import * as React from "react"
// import { useEffect, useState } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Chart from "../components/chart/chart"
import "../components/mixins/buttons.scss"

const QuotePage = ({ ticker, pageContext }) => {
    const [loader, setLoader] = React.useState(true);
    const [timeFrame, setTimeFrame] = React.useState('D');
    const [ma, setMA] = React.useState(false);
    // const [ma50, setMa50] = React.useState(false);
    // const [ma100, setMa100] = React.useState(false);
    // const [ma200, setMa200] = React.useState(false);
    const [tickerData, setTickerData] = React.useState(null);
    const [tickerDataError, setTickerDataError] = React.useState(null);
    const [chartData, setChartData] = React.useState(null);
    const [chartDataError, setChartDataError] = React.useState(null);

    React.useEffect(() => {
        fetch(
            `/api/tickers/${ticker}/`
        ).then(
            function(response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            }
        ).then(
            res => setTickerData(res)
        ).catch(
            error => setTickerDataError(error)
        )
    }, [])

    // if (tickerData) {
    React.useEffect(() => {
        setChartData(null)
        setLoader(true)
        fetch(
            `/api/trend?t=${ticker}&timeframe=${timeFrame}`
        ).then(
            function(response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            }
        ).then(
            res => setChartData(res)
        ).catch(
            error => setChartDataError(error)
        ).finally(
            () => setLoader(false)
        );
    }, [timeFrame])
    // }

  return <Layout searchInHeader={true}>
    <Seo title={`${ticker} trend lines`} description={`Autogenerated trend lines on a stock chart for ${ticker}`}/>
    <div className="row py-3 py-lg-4 text-center">
        <div className="col-lg-6 col-md-8 mx-auto">
            <h1 className="fw-light h4">                
                    <strong>{tickerData && `${tickerData.name} (${ticker})`}
                    {tickerDataError && ticker}
                    </strong>
                <br/>
                <small className="text-secondary">{ (tickerData || tickerDataError) && "Stock chart with trend lines"}</small>
                <br/>
            </h1>
        </div>
    </div>
  <div className="row">
    {/*            <h3 class="col-9"><small class="text-secondary">Stock chart with trend lines</small></h3>*/}
    {loader && 
        <div className="col-12 text-center mt-4" id="loader">
        <span
            className="spinner-border text-secondary text-center"
            role="status"
        />
        </div>
    }
    {chartDataError &&
        <div
            className="col-12 text-center mt-4 text-danger"
            id="failed-message"
        >
            Sorry, failed to build chart for this ticker
        </div>
    }
    {chartData && <React.Fragment>

        <div className="col-12" id="chart-controls">
            <div
                className="btn-group"
                // data-toggle="buttons"
                id="ma-contols"
                title="50/100/200-day Moving Average"
            >
                <input
                    type="checkbox"
                    className="btn-check"
                    name="ma"
                    id="ma"
                    defaultValue="50"
                    autoComplete="off"
                    disabled={timeFrame != 'D'}
                    checked={ma && (timeFrame == 'D')}
                    onClick={() => {setMA(!ma); return true}}
                />
                <label className="btn btn-trender-light btn-sm" for="ma">
                    MA 50/100/200
                </label>
                {/*
                <input
                    type="checkbox"
                    className="btn-check"
                    name="ma50"
                    id="ma50"
                    defaultValue="50"
                    autoComplete="off"
                    checked={ma50}
                    onClick={() => {setMa50(true); return true}}
                />
                <label className="btn btn-trender-light btn-sm" for="ma50">
                    &nbsp;50&nbsp;
                </label>
                
                <input
                    type="checkbox"
                    className="btn-check"
                    name="ma100"
                    id="ma100"
                    defaultValue="100"
                    autoComplete="off"
                    checked={ma100}
                    onClick={() => {setMa100(true); return true}}
                />
                <label className="btn btn-trender-light btn-sm" for="ma100">
                    &nbsp;100&nbsp;
                </label>
                
                <input
                    type="checkbox"
                    className="btn-check"
                    name="ma200"
                    id="ma200"
                    defaultValue="M"
                    autoComplete="off"
                    checked={ma200}
                    onClick={() => {setMa200(true); return true}}
                />
                <label className="btn btn-trender-light btn-sm" for="ma200">
                    &nbsp;200&nbsp;
                </label> */}
            </div>

            <div
                className="btn-group float-end"
                data-toggle="buttons"
                id="timeframe-contol"
                title="Time frame"
            >
                <input
                    type="radio"
                    className="btn-check"
                    name="timeFrame"
                    id="timeFrame-D"
                    defaultValue="D"
                    autoComplete="off"
                    checked={timeFrame == 'D'}
                    onClick={() => {setTimeFrame('D'); return true}}
                />
                <label className="btn btn-trender-light btn-sm" for="timeFrame-D">
                    &nbsp;D&nbsp;
                </label>
                
                <input
                    type="radio"
                    className="btn-check"
                    name="timeFrame"
                    id="timeFrame-W"
                    defaultValue="W"
                    autoComplete="off"
                    checked={timeFrame == 'W'}
                    onClick={() => {setTimeFrame('W'); return true}}
                />
                <label className="btn btn-trender-light btn-sm" for="timeFrame-W">
                    &nbsp;W&nbsp;
                </label>
                
                <input
                    type="radio"
                    className="btn-check"
                    name="timeFrame"
                    id="timeFrame-M"
                    defaultValue="M"
                    autoComplete="off"
                    checked={timeFrame == 'M'}
                    onClick={() => {setTimeFrame('M'); return true}}
                />
                <label className="btn btn-trender-light btn-sm" for="timeFrame-M">
                    &nbsp;M&nbsp;
                </label>
            </div>
        </div>
        <Chart chartData={chartData.chart_data} markupData={chartData.markup_data} ma={ma && (timeFrame == 'D')}/>
    </React.Fragment>
    }
</div>
  </Layout>
}

export default QuotePage
