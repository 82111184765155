import * as React from "react"
import { createChart } from 'lightweight-charts';
import useWindowDimensions from "../../hooks/window";


var COLORS = {
    'D': "#2d78ff",
    'W': "#8e24aa",
    'M': "#efd334",
}


function drawChart(ref, DATA, MARKUP_DATA, windowHeight, windowWidth, ma) {
    // $('#chart').empty();
    // ref.current.reset();
    // var chartWidth = ref.current.offsetWidth;
    console.log('current', ref.current);
    console.log('offsetWidth', ref.current.offsetWidth);
    console.log('offsetTop', ref.current.offsetTop);
    console.log('innerWidth', ref.current.innerWidth);
    var chartWidth = ref.current.innerWidth;
    console.log('DRAW CHART');//, chartWidth);
    console.log('windowWidth', windowWidth);
    // console.log('CHART WIDTH', chartWidth);
    // console.log('CHART WIDTH 1', ref.parent);
    // console.log('CHART WIDTH 2', ref.current.parent);
    // console.log('CHART WIDTH 3', ref.current.offsetTop);
    
    // var chartHeight = Math.max(200, Math.min(windowHeight - ref.current.offsetTop - 40, 600));
    var chartHeight = Math.max(200, Math.min(windowHeight - 270, 600));
    console.log('chartHeight', chartHeight);
    var chart = createChart(ref.current, {
        width: chartWidth,
        height: chartHeight // 600
    });

    // Volume
    var volumeSeries = chart.addHistogramSeries({
        color: '#bbb',//'#26a69a',
        priceFormat: {
            type: 'volume',
        },
        priceScaleId: '',
        scaleMargins: {
            top: 0.8,
            bottom: 0,
        },
    });
    var volumeData = generateVolumesData(DATA);
    volumeSeries.setData(volumeData)
    
    // Candles
    var candleSeries = chart.addCandlestickSeries();
    var period = {
        timeFrom: DATA[0].time, //{ day: 1, month: 1, year: 2018 },
        timeTo: DATA[DATA.length - 1].time, //{ day: 1, month: 1, year: 2019 },
    };
    var data = generateBarsData(DATA, period);
    candleSeries.setData(data);

    // Trend lines
    for (var i = 0; i < MARKUP_DATA.trends.length; i++) {
        var line = MARKUP_DATA.trends[i];
        var linesSeries = chart.addLineSeries({
            color: COLORS[line.timeframe],
            lineWidth: line.score / 5,
            lastValueVisible: false,
            priceLineVisible: false,
        });
        linesSeries.setData(line.coords);
    }

    // MA
    if (ma) {
        var smaData50 = calculateSMA(data, 50);
        var smaLine = chart.addLineSeries({
            color: '#4f0',
            lineWidth: 1,
        });
        smaLine.setData(smaData50);

        var smaData100 = calculateSMA(data, 100);
        var smaLine = chart.addLineSeries({
            color: '#fc0',
            lineWidth: 1,
        });
        smaLine.setData(smaData100);

        var smaData200 = calculateSMA(data, 200);
        var smaLine = chart.addLineSeries({
            color: '#f40',
            lineWidth: 1,
        });
        smaLine.setData(smaData200);
    }

    var markers = [];
    for (var i = 0; i < MARKUP_DATA.markers.length; i++) {
        var marker = MARKUP_DATA.markers[i]
        var color;
        var pos;
        if (marker.type == 'min') {
            color = '#e60000';
            pos = 'belowBar';
        }
        if (marker.type == 'max') {
            color = '#00cc00';
            pos = 'aboveBar';
        }
        markers.push({ time: marker.time, position: pos, color: color, shape: 'circle', text: marker.text});
    }
    candleSeries.setMarkers(markers);

    var timeScale = chart.timeScale();
    var timer = null;
    timeScale.subscribeVisibleLogicalRangeChange(() => {
        if (timer !== null) {
            return;
        }
        timer = setTimeout(() => {
         var logicalRange = timeScale.getVisibleLogicalRange();
         if (logicalRange !== null) {
             var barsInfo = candleSeries.barsInLogicalRange(logicalRange);
             if (barsInfo !== null && barsInfo.barsBefore < 10) {
                 var firstTime = getBusinessDayBeforeCurrentAt(data[0].time, 1);
                 var lastTime = getBusinessDayBeforeCurrentAt(firstTime, Math.max(100, -barsInfo.barsBefore + 100));
                 var newPeriod = {
                     timeFrom: lastTime,
                     timeTo: firstTime,
                 };
                 data = [...generateBarsData(DATA, newPeriod), ...data];
                 candleSeries.setData(data);
             }
         }
         timer = null;
        }, 50);
    });

    return chart;
}


function getBusinessDayBeforeCurrentAt(date, daysDelta) {
    const dateWithDelta = new Date(Date.UTC(date.year, date.month - 1, date.day - daysDelta, 0, 0, 0, 0));
    return { year: dateWithDelta.getFullYear(), month: dateWithDelta.getMonth() + 1, day: dateWithDelta.getDate() };
}

function generateBarsData(DATA, period) {
    var res = [];
    var timeFromInt = period.timeFrom.year * 10000 + period.timeFrom.month * 100 + period.timeFrom.day;
    var timeToInt = period.timeTo.year * 10000 + period.timeTo.month * 100 + period.timeTo.day
    for (var i = 0; i < DATA.length; i++) {
        var row = DATA[i];
        var dtInt = row.time.year * 10000 + row.time.month * 100 + row.time.day;
        if (dtInt < timeFromInt) {
            continue;
        }
        if (dtInt > timeToInt) {
            continue;
        }
        if (row.open != null) {
            res.push(row);
        }
    }
    return res;
}

function generateVolumesData(candlesData) {
    var res = [];
    for (var i = 0; i < candlesData.length; i++) {
        var row = candlesData[i];
        res.push({
            time: row.time,
            value: row.volume
        })
    }
    return res;
}

function calculateSMA(data, count){
    var avg = function(data) {
      var sum = 0;
      for (var i = 0; i < data.length; i++) {
         sum += data[i].close;
      }
      return sum / data.length;
    };
    var result = [];
    for (var i=count - 1, len=data.length; i < len; i++){
      var val = avg(data.slice(i - count + 1, i));
      result.push({ time: data[i].time, value: val});
    }
    return result;
}


const Chart = ({chartData, markupData, ma}) => {
    // console.log('CHART_DATA', chartData);
    // console.log('MARKUP_DATA', markupData);
    const [chart, setChart] = React.useState(null);

    const { height, width } = useWindowDimensions();
    console.log('width', width);
    console.log('height', height);


    const ref = React.useRef(null);
    
    React.useEffect(() => {
        if (chart) {
            chart.remove()
        }
        setChart(
            drawChart(ref, chartData, markupData, height, width, ma)
        )
    }, [height, width, ma])

    
    // https://www.npmjs.com/package/lightweight-charts
    return <div className="col-12 mt-3">
                <div ref={ref} id="chart"></div>
           </div>
}

export default Chart
